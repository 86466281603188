/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToggleModuleResponse
 */
export interface ToggleModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof ToggleModuleResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ToggleModuleResponse
     */
    module_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ToggleModuleResponse
     */
    is_active: boolean;
}

/**
 * Check if a given object implements the ToggleModuleResponse interface.
 */
export function instanceOfToggleModuleResponse(value: object): value is ToggleModuleResponse {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('module_id' in value) || value['module_id'] === undefined) return false;
    if (!('is_active' in value) || value['is_active'] === undefined) return false;
    return true;
}

export function ToggleModuleResponseFromJSON(json: any): ToggleModuleResponse {
    return ToggleModuleResponseFromJSONTyped(json, false);
}

export function ToggleModuleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToggleModuleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'module_id': json['module_id'],
        'is_active': json['is_active'],
    };
}

export function ToggleModuleResponseToJSON(json: any): ToggleModuleResponse {
    return ToggleModuleResponseToJSONTyped(json, false);
}

export function ToggleModuleResponseToJSONTyped(value?: ToggleModuleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
        'module_id': value['module_id'],
        'is_active': value['is_active'],
    };
}

