<template>
  <NuxtPage @sgdf-notify="log"></NuxtPage>
  <Notifications />
  <VueQueryDevtools />
</template>

<script setup lang="ts">
import "@sgdf/ui-library/styles/reset.css";
import "@sgdf/ui-library/themes/default.css";
import "@sgdf/ui-library/themes/binding.css";
import "@sgdf/ui-library/sgdf-button.js";
import "@sgdf/ui-library/sgdf-heading.js";
import "@sgdf/ui-library/sgdf-icon.js";
import "@sgdf/ui-library/sgdf-spinner.js";
import "@sgdf/ui-library/sgdf-text-bloc.js";
import "@sgdf/ui-library/sgdf-team-card.js";
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

import Notifications from "./components/Notifications.vue";
const { notify } = useNotifyStore();

function log(
  e: CustomEvent<{
    variant: "primary" | "success" | "neutral" | "warning" | "danger";
    message: string;
    icon?: string;
    duration?: number;
  }>
) {
  notify(
    e.detail.message,
    variantToNotificationType(e.detail.variant),
    e.detail.icon,
    e.detail.duration
  );
}

document.documentElement.style.setProperty(
  "--vh",
  window.innerHeight * 0.01 + "px"
);
</script>

<style>
.h-screen {
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
</style>
