import { default as completeRhzT3nJz4jMeta } from "/app/webapp/pages/auth/complete.vue?macro=true";
import { default as intrassocasyhMQbdbdMeta } from "/app/webapp/pages/auth/intrassoc.vue?macro=true";
import { default as resetaobnRrZeh0Meta } from "/app/webapp/pages/auth/password/reset.vue?macro=true";
import { default as deontologiekfxODYCWSNMeta } from "/app/webapp/pages/deontologie.vue?macro=true";
import { default as indexQ2teV6cG24Meta } from "/app/webapp/pages/gestionnaire/[meetingId]/index.vue?macro=true";
import { default as indexqkJBIcmwypMeta } from "/app/webapp/pages/gestionnaire/index.vue?macro=true";
import { default as my_45scopem8Yb7wzK4RMeta } from "/app/webapp/pages/gestionnaire/my-scope.vue?macro=true";
import { default as opinion_45reviewUQMLqCkQtyMeta } from "/app/webapp/pages/gestionnaire/opinion-review.vue?macro=true";
import { default as participants_45exportGxAEon3nHuMeta } from "/app/webapp/pages/gestionnaire/participants-export.vue?macro=true";
import { default as index1YCNhmZamnMeta } from "/app/webapp/pages/index.vue?macro=true";
import { default as loginYX922PwNPgMeta } from "/app/webapp/pages/login.vue?macro=true";
import { default as mentionsN3alzi0oknMeta } from "/app/webapp/pages/mentions.vue?macro=true";
import { default as mes_45infosvxX0BYWrmXMeta } from "/app/webapp/pages/mes-infos.vue?macro=true";
import { default as politique_45confidentialite71Yzd0M5vLMeta } from "/app/webapp/pages/politique-confidentialite.vue?macro=true";
import { default as documentsxqy7ZKWiNAMeta } from "/app/webapp/pages/stages/[meetingId]/documents.vue?macro=true";
import { default as _91dailyTaskTeamId_93Akniq1lq8zMeta } from "/app/webapp/pages/stages/[meetingId]/equipes-de-vie/[dailyTaskTeamId].vue?macro=true";
import { default as _91teamId_93y7o2H2xadFMeta } from "/app/webapp/pages/stages/[meetingId]/equipes/[teamId].vue?macro=true";
import { default as indexL6q5wzctRqMeta } from "/app/webapp/pages/stages/[meetingId]/equipes/index.vue?macro=true";
import { default as indexHN39kRWZD8Meta } from "/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/index.vue?macro=true";
import { default as reponseNH8BTE2WxGMeta } from "/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/reponse.vue?macro=true";
import { default as indexMCTIuISbe5Meta } from "/app/webapp/pages/stages/[meetingId]/formulaires/index.vue?macro=true";
import { default as indexGUSILdz9YcMeta } from "/app/webapp/pages/stages/[meetingId]/index.vue?macro=true";
import { default as mes_45infosS1KDT1C3d5Meta } from "/app/webapp/pages/stages/[meetingId]/mes-infos.vue?macro=true";
import { default as exportFUtLRqhuAWMeta } from "/app/webapp/pages/stages/[meetingId]/notes/[noteId]/export.vue?macro=true";
import { default as indexJsBjFysGt6Meta } from "/app/webapp/pages/stages/[meetingId]/notes/index.vue?macro=true";
import { default as documentspFXnern4iNMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/documents.vue?macro=true";
import { default as equipesadTRILdZ5ZMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/equipes.vue?macro=true";
import { default as exportsA4wLQKIF2SMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/exports.vue?macro=true";
import { default as formateursWI4MjnQWuCMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formateurs.vue?macro=true";
import { default as apercu36fuLm8F8XMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/apercu.vue?macro=true";
import { default as indexnfuivBzg6YMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/index.vue?macro=true";
import { default as reponsesoTk5xXavChMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/reponses.vue?macro=true";
import { default as indexfJsspuJ0smMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/index.vue?macro=true";
import { default as indexB6rJFOpR1jMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/index.vue?macro=true";
import { default as opinion_45reviewck2Eru9TGPMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/opinion-review.vue?macro=true";
import { default as participantsjqeK5dU1hiMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/participants.vue?macro=true";
import { default as planning_45creationMZj1pssvCyMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/planning-creation.vue?macro=true";
import { default as planningIEHQCOnOOXMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/planning.vue?macro=true";
import { default as sallesZtw9061owgMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/salles.vue?macro=true";
import { default as servicesOybSXW1Z8PMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/services.vue?macro=true";
import { default as tagada8xtDeW0lngMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/tagada.vue?macro=true";
import { default as participantSdq9C3ZLDpMeta } from "/app/webapp/pages/stages/[meetingId]/participant.vue?macro=true";
import { default as check_45inuScKYLgQ3MMeta } from "/app/webapp/pages/stages/[meetingId]/participants/[participantId]/check-in.vue?macro=true";
import { default as indexkwPZujsnkiMeta } from "/app/webapp/pages/stages/[meetingId]/participants/index.vue?macro=true";
import { default as planning_45formateursu4JNvt2EF4Meta } from "/app/webapp/pages/stages/[meetingId]/planning-formateurs.vue?macro=true";
import { default as planning_45impressionZ0cBUOl55nMeta } from "/app/webapp/pages/stages/[meetingId]/planning-impression.vue?macro=true";
import { default as planningnkiBnGVS19Meta } from "/app/webapp/pages/stages/[meetingId]/planning.vue?macro=true";
import { default as servicesB129eJa9rwMeta } from "/app/webapp/pages/stages/[meetingId]/services.vue?macro=true";
export default [
  {
    name: completeRhzT3nJz4jMeta?.name ?? "auth-complete",
    path: completeRhzT3nJz4jMeta?.path ?? "/auth/complete",
    meta: completeRhzT3nJz4jMeta || {},
    alias: completeRhzT3nJz4jMeta?.alias || [],
    redirect: completeRhzT3nJz4jMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/auth/complete.vue").then(m => m.default || m)
  },
  {
    name: intrassocasyhMQbdbdMeta?.name ?? "auth-intrassoc",
    path: intrassocasyhMQbdbdMeta?.path ?? "/auth/intrassoc",
    meta: intrassocasyhMQbdbdMeta || {},
    alias: intrassocasyhMQbdbdMeta?.alias || [],
    redirect: intrassocasyhMQbdbdMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/auth/intrassoc.vue").then(m => m.default || m)
  },
  {
    name: resetaobnRrZeh0Meta?.name ?? "auth-password-reset",
    path: resetaobnRrZeh0Meta?.path ?? "/auth/password/reset",
    meta: resetaobnRrZeh0Meta || {},
    alias: resetaobnRrZeh0Meta?.alias || [],
    redirect: resetaobnRrZeh0Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: deontologiekfxODYCWSNMeta?.name ?? "deontologie",
    path: deontologiekfxODYCWSNMeta?.path ?? "/deontologie",
    meta: deontologiekfxODYCWSNMeta || {},
    alias: deontologiekfxODYCWSNMeta?.alias || [],
    redirect: deontologiekfxODYCWSNMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/deontologie.vue").then(m => m.default || m)
  },
  {
    name: indexQ2teV6cG24Meta?.name ?? "gestionnaire-meetingId",
    path: indexQ2teV6cG24Meta?.path ?? "/gestionnaire/:meetingId()",
    meta: indexQ2teV6cG24Meta || {},
    alias: indexQ2teV6cG24Meta?.alias || [],
    redirect: indexQ2teV6cG24Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/gestionnaire/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkJBIcmwypMeta?.name ?? "gestionnaire",
    path: indexqkJBIcmwypMeta?.path ?? "/gestionnaire",
    meta: indexqkJBIcmwypMeta || {},
    alias: indexqkJBIcmwypMeta?.alias || [],
    redirect: indexqkJBIcmwypMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/gestionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: my_45scopem8Yb7wzK4RMeta?.name ?? "gestionnaire-my-scope",
    path: my_45scopem8Yb7wzK4RMeta?.path ?? "/gestionnaire/my-scope",
    meta: my_45scopem8Yb7wzK4RMeta || {},
    alias: my_45scopem8Yb7wzK4RMeta?.alias || [],
    redirect: my_45scopem8Yb7wzK4RMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/gestionnaire/my-scope.vue").then(m => m.default || m)
  },
  {
    name: opinion_45reviewUQMLqCkQtyMeta?.name ?? "gestionnaire-opinion-review",
    path: opinion_45reviewUQMLqCkQtyMeta?.path ?? "/gestionnaire/opinion-review",
    meta: opinion_45reviewUQMLqCkQtyMeta || {},
    alias: opinion_45reviewUQMLqCkQtyMeta?.alias || [],
    redirect: opinion_45reviewUQMLqCkQtyMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/gestionnaire/opinion-review.vue").then(m => m.default || m)
  },
  {
    name: participants_45exportGxAEon3nHuMeta?.name ?? "gestionnaire-participants-export",
    path: participants_45exportGxAEon3nHuMeta?.path ?? "/gestionnaire/participants-export",
    meta: participants_45exportGxAEon3nHuMeta || {},
    alias: participants_45exportGxAEon3nHuMeta?.alias || [],
    redirect: participants_45exportGxAEon3nHuMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/gestionnaire/participants-export.vue").then(m => m.default || m)
  },
  {
    name: index1YCNhmZamnMeta?.name ?? "index",
    path: index1YCNhmZamnMeta?.path ?? "/",
    meta: index1YCNhmZamnMeta || {},
    alias: index1YCNhmZamnMeta?.alias || [],
    redirect: index1YCNhmZamnMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginYX922PwNPgMeta?.name ?? "login",
    path: loginYX922PwNPgMeta?.path ?? "/login",
    meta: loginYX922PwNPgMeta || {},
    alias: loginYX922PwNPgMeta?.alias || [],
    redirect: loginYX922PwNPgMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mentionsN3alzi0oknMeta?.name ?? "mentions",
    path: mentionsN3alzi0oknMeta?.path ?? "/mentions",
    meta: mentionsN3alzi0oknMeta || {},
    alias: mentionsN3alzi0oknMeta?.alias || [],
    redirect: mentionsN3alzi0oknMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/mentions.vue").then(m => m.default || m)
  },
  {
    name: mes_45infosvxX0BYWrmXMeta?.name ?? "mes-infos",
    path: mes_45infosvxX0BYWrmXMeta?.path ?? "/mes-infos",
    meta: mes_45infosvxX0BYWrmXMeta || {},
    alias: mes_45infosvxX0BYWrmXMeta?.alias || [],
    redirect: mes_45infosvxX0BYWrmXMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/mes-infos.vue").then(m => m.default || m)
  },
  {
    name: politique_45confidentialite71Yzd0M5vLMeta?.name ?? "politique-confidentialite",
    path: politique_45confidentialite71Yzd0M5vLMeta?.path ?? "/politique-confidentialite",
    meta: politique_45confidentialite71Yzd0M5vLMeta || {},
    alias: politique_45confidentialite71Yzd0M5vLMeta?.alias || [],
    redirect: politique_45confidentialite71Yzd0M5vLMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: documentsxqy7ZKWiNAMeta?.name ?? "stages-meetingId-documents",
    path: documentsxqy7ZKWiNAMeta?.path ?? "/stages/:meetingId()/documents",
    meta: documentsxqy7ZKWiNAMeta || {},
    alias: documentsxqy7ZKWiNAMeta?.alias || [],
    redirect: documentsxqy7ZKWiNAMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/documents.vue").then(m => m.default || m)
  },
  {
    name: _91dailyTaskTeamId_93Akniq1lq8zMeta?.name ?? "stages-meetingId-equipes-de-vie-dailyTaskTeamId",
    path: _91dailyTaskTeamId_93Akniq1lq8zMeta?.path ?? "/stages/:meetingId()/equipes-de-vie/:dailyTaskTeamId()",
    meta: _91dailyTaskTeamId_93Akniq1lq8zMeta || {},
    alias: _91dailyTaskTeamId_93Akniq1lq8zMeta?.alias || [],
    redirect: _91dailyTaskTeamId_93Akniq1lq8zMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes-de-vie/[dailyTaskTeamId].vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93y7o2H2xadFMeta?.name ?? "stages-meetingId-equipes-teamId",
    path: _91teamId_93y7o2H2xadFMeta?.path ?? "/stages/:meetingId()/equipes/:teamId()",
    meta: _91teamId_93y7o2H2xadFMeta || {},
    alias: _91teamId_93y7o2H2xadFMeta?.alias || [],
    redirect: _91teamId_93y7o2H2xadFMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes/[teamId].vue").then(m => m.default || m)
  },
  {
    name: indexL6q5wzctRqMeta?.name ?? "stages-meetingId-equipes",
    path: indexL6q5wzctRqMeta?.path ?? "/stages/:meetingId()/equipes",
    meta: indexL6q5wzctRqMeta || {},
    alias: indexL6q5wzctRqMeta?.alias || [],
    redirect: indexL6q5wzctRqMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes/index.vue").then(m => m.default || m)
  },
  {
    name: indexHN39kRWZD8Meta?.name ?? "stages-meetingId-formulaires-formId",
    path: indexHN39kRWZD8Meta?.path ?? "/stages/:meetingId()/formulaires/:formId()",
    meta: indexHN39kRWZD8Meta || {},
    alias: indexHN39kRWZD8Meta?.alias || [],
    redirect: indexHN39kRWZD8Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/index.vue").then(m => m.default || m)
  },
  {
    name: reponseNH8BTE2WxGMeta?.name ?? "stages-meetingId-formulaires-formId-reponse",
    path: reponseNH8BTE2WxGMeta?.path ?? "/stages/:meetingId()/formulaires/:formId()/reponse",
    meta: reponseNH8BTE2WxGMeta || {},
    alias: reponseNH8BTE2WxGMeta?.alias || [],
    redirect: reponseNH8BTE2WxGMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/reponse.vue").then(m => m.default || m)
  },
  {
    name: indexMCTIuISbe5Meta?.name ?? "stages-meetingId-formulaires",
    path: indexMCTIuISbe5Meta?.path ?? "/stages/:meetingId()/formulaires",
    meta: indexMCTIuISbe5Meta || {},
    alias: indexMCTIuISbe5Meta?.alias || [],
    redirect: indexMCTIuISbe5Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/index.vue").then(m => m.default || m)
  },
  {
    name: indexGUSILdz9YcMeta?.name ?? "stages-meetingId",
    path: indexGUSILdz9YcMeta?.path ?? "/stages/:meetingId()",
    meta: indexGUSILdz9YcMeta || {},
    alias: indexGUSILdz9YcMeta?.alias || [],
    redirect: indexGUSILdz9YcMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: mes_45infosS1KDT1C3d5Meta?.name ?? "stages-meetingId-mes-infos",
    path: mes_45infosS1KDT1C3d5Meta?.path ?? "/stages/:meetingId()/mes-infos",
    meta: mes_45infosS1KDT1C3d5Meta || {},
    alias: mes_45infosS1KDT1C3d5Meta?.alias || [],
    redirect: mes_45infosS1KDT1C3d5Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/mes-infos.vue").then(m => m.default || m)
  },
  {
    name: exportFUtLRqhuAWMeta?.name ?? "stages-meetingId-notes-noteId-export",
    path: exportFUtLRqhuAWMeta?.path ?? "/stages/:meetingId()/notes/:noteId()/export",
    meta: exportFUtLRqhuAWMeta || {},
    alias: exportFUtLRqhuAWMeta?.alias || [],
    redirect: exportFUtLRqhuAWMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/notes/[noteId]/export.vue").then(m => m.default || m)
  },
  {
    name: indexJsBjFysGt6Meta?.name ?? "stages-meetingId-notes",
    path: indexJsBjFysGt6Meta?.path ?? "/stages/:meetingId()/notes",
    meta: indexJsBjFysGt6Meta || {},
    alias: indexJsBjFysGt6Meta?.alias || [],
    redirect: indexJsBjFysGt6Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/notes/index.vue").then(m => m.default || m)
  },
  {
    name: documentspFXnern4iNMeta?.name ?? "stages-meetingId-parametrage-documents",
    path: documentspFXnern4iNMeta?.path ?? "/stages/:meetingId()/parametrage/documents",
    meta: documentspFXnern4iNMeta || {},
    alias: documentspFXnern4iNMeta?.alias || [],
    redirect: documentspFXnern4iNMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/documents.vue").then(m => m.default || m)
  },
  {
    name: equipesadTRILdZ5ZMeta?.name ?? "stages-meetingId-parametrage-equipes",
    path: equipesadTRILdZ5ZMeta?.path ?? "/stages/:meetingId()/parametrage/equipes",
    meta: equipesadTRILdZ5ZMeta || {},
    alias: equipesadTRILdZ5ZMeta?.alias || [],
    redirect: equipesadTRILdZ5ZMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/equipes.vue").then(m => m.default || m)
  },
  {
    name: exportsA4wLQKIF2SMeta?.name ?? "stages-meetingId-parametrage-exports",
    path: exportsA4wLQKIF2SMeta?.path ?? "/stages/:meetingId()/parametrage/exports",
    meta: exportsA4wLQKIF2SMeta || {},
    alias: exportsA4wLQKIF2SMeta?.alias || [],
    redirect: exportsA4wLQKIF2SMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/exports.vue").then(m => m.default || m)
  },
  {
    name: formateursWI4MjnQWuCMeta?.name ?? "stages-meetingId-parametrage-formateurs",
    path: formateursWI4MjnQWuCMeta?.path ?? "/stages/:meetingId()/parametrage/formateurs",
    meta: formateursWI4MjnQWuCMeta || {},
    alias: formateursWI4MjnQWuCMeta?.alias || [],
    redirect: formateursWI4MjnQWuCMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formateurs.vue").then(m => m.default || m)
  },
  {
    name: apercu36fuLm8F8XMeta?.name ?? "stages-meetingId-parametrage-formulaires-formId-apercu",
    path: apercu36fuLm8F8XMeta?.path ?? "/stages/:meetingId()/parametrage/formulaires/:formId()/apercu",
    meta: apercu36fuLm8F8XMeta || {},
    alias: apercu36fuLm8F8XMeta?.alias || [],
    redirect: apercu36fuLm8F8XMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/apercu.vue").then(m => m.default || m)
  },
  {
    name: indexnfuivBzg6YMeta?.name ?? "stages-meetingId-parametrage-formulaires-formId",
    path: indexnfuivBzg6YMeta?.path ?? "/stages/:meetingId()/parametrage/formulaires/:formId()",
    meta: indexnfuivBzg6YMeta || {},
    alias: indexnfuivBzg6YMeta?.alias || [],
    redirect: indexnfuivBzg6YMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/index.vue").then(m => m.default || m)
  },
  {
    name: reponsesoTk5xXavChMeta?.name ?? "stages-meetingId-parametrage-formulaires-formId-reponses",
    path: reponsesoTk5xXavChMeta?.path ?? "/stages/:meetingId()/parametrage/formulaires/:formId()/reponses",
    meta: reponsesoTk5xXavChMeta || {},
    alias: reponsesoTk5xXavChMeta?.alias || [],
    redirect: reponsesoTk5xXavChMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/reponses.vue").then(m => m.default || m)
  },
  {
    name: indexfJsspuJ0smMeta?.name ?? "stages-meetingId-parametrage-formulaires",
    path: indexfJsspuJ0smMeta?.path ?? "/stages/:meetingId()/parametrage/formulaires",
    meta: indexfJsspuJ0smMeta || {},
    alias: indexfJsspuJ0smMeta?.alias || [],
    redirect: indexfJsspuJ0smMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6rJFOpR1jMeta?.name ?? "stages-meetingId-parametrage",
    path: indexB6rJFOpR1jMeta?.path ?? "/stages/:meetingId()/parametrage",
    meta: indexB6rJFOpR1jMeta || {},
    alias: indexB6rJFOpR1jMeta?.alias || [],
    redirect: indexB6rJFOpR1jMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/index.vue").then(m => m.default || m)
  },
  {
    name: opinion_45reviewck2Eru9TGPMeta?.name ?? "stages-meetingId-parametrage-opinion-review",
    path: opinion_45reviewck2Eru9TGPMeta?.path ?? "/stages/:meetingId()/parametrage/opinion-review",
    meta: opinion_45reviewck2Eru9TGPMeta || {},
    alias: opinion_45reviewck2Eru9TGPMeta?.alias || [],
    redirect: opinion_45reviewck2Eru9TGPMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/opinion-review.vue").then(m => m.default || m)
  },
  {
    name: participantsjqeK5dU1hiMeta?.name ?? "stages-meetingId-parametrage-participants",
    path: participantsjqeK5dU1hiMeta?.path ?? "/stages/:meetingId()/parametrage/participants",
    meta: participantsjqeK5dU1hiMeta || {},
    alias: participantsjqeK5dU1hiMeta?.alias || [],
    redirect: participantsjqeK5dU1hiMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/participants.vue").then(m => m.default || m)
  },
  {
    name: planning_45creationMZj1pssvCyMeta?.name ?? "stages-meetingId-parametrage-planning-creation",
    path: planning_45creationMZj1pssvCyMeta?.path ?? "/stages/:meetingId()/parametrage/planning-creation",
    meta: planning_45creationMZj1pssvCyMeta || {},
    alias: planning_45creationMZj1pssvCyMeta?.alias || [],
    redirect: planning_45creationMZj1pssvCyMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/planning-creation.vue").then(m => m.default || m)
  },
  {
    name: planningIEHQCOnOOXMeta?.name ?? "stages-meetingId-parametrage-planning",
    path: planningIEHQCOnOOXMeta?.path ?? "/stages/:meetingId()/parametrage/planning",
    meta: planningIEHQCOnOOXMeta || {},
    alias: planningIEHQCOnOOXMeta?.alias || [],
    redirect: planningIEHQCOnOOXMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/planning.vue").then(m => m.default || m)
  },
  {
    name: sallesZtw9061owgMeta?.name ?? "stages-meetingId-parametrage-salles",
    path: sallesZtw9061owgMeta?.path ?? "/stages/:meetingId()/parametrage/salles",
    meta: sallesZtw9061owgMeta || {},
    alias: sallesZtw9061owgMeta?.alias || [],
    redirect: sallesZtw9061owgMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/salles.vue").then(m => m.default || m)
  },
  {
    name: servicesOybSXW1Z8PMeta?.name ?? "stages-meetingId-parametrage-services",
    path: servicesOybSXW1Z8PMeta?.path ?? "/stages/:meetingId()/parametrage/services",
    meta: servicesOybSXW1Z8PMeta || {},
    alias: servicesOybSXW1Z8PMeta?.alias || [],
    redirect: servicesOybSXW1Z8PMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/services.vue").then(m => m.default || m)
  },
  {
    name: tagada8xtDeW0lngMeta?.name ?? "stages-meetingId-parametrage-tagada",
    path: tagada8xtDeW0lngMeta?.path ?? "/stages/:meetingId()/parametrage/tagada",
    meta: tagada8xtDeW0lngMeta || {},
    alias: tagada8xtDeW0lngMeta?.alias || [],
    redirect: tagada8xtDeW0lngMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/tagada.vue").then(m => m.default || m)
  },
  {
    name: participantSdq9C3ZLDpMeta?.name ?? "stages-meetingId-participant",
    path: participantSdq9C3ZLDpMeta?.path ?? "/stages/:meetingId()/participant",
    meta: participantSdq9C3ZLDpMeta || {},
    alias: participantSdq9C3ZLDpMeta?.alias || [],
    redirect: participantSdq9C3ZLDpMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/participant.vue").then(m => m.default || m)
  },
  {
    name: check_45inuScKYLgQ3MMeta?.name ?? "stages-meetingId-participants-participantId-check-in",
    path: check_45inuScKYLgQ3MMeta?.path ?? "/stages/:meetingId()/participants/:participantId()/check-in",
    meta: check_45inuScKYLgQ3MMeta || {},
    alias: check_45inuScKYLgQ3MMeta?.alias || [],
    redirect: check_45inuScKYLgQ3MMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/participants/[participantId]/check-in.vue").then(m => m.default || m)
  },
  {
    name: indexkwPZujsnkiMeta?.name ?? "stages-meetingId-participants",
    path: indexkwPZujsnkiMeta?.path ?? "/stages/:meetingId()/participants",
    meta: indexkwPZujsnkiMeta || {},
    alias: indexkwPZujsnkiMeta?.alias || [],
    redirect: indexkwPZujsnkiMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/participants/index.vue").then(m => m.default || m)
  },
  {
    name: planning_45formateursu4JNvt2EF4Meta?.name ?? "stages-meetingId-planning-formateurs",
    path: planning_45formateursu4JNvt2EF4Meta?.path ?? "/stages/:meetingId()/planning-formateurs",
    meta: planning_45formateursu4JNvt2EF4Meta || {},
    alias: planning_45formateursu4JNvt2EF4Meta?.alias || [],
    redirect: planning_45formateursu4JNvt2EF4Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning-formateurs.vue").then(m => m.default || m)
  },
  {
    name: planning_45impressionZ0cBUOl55nMeta?.name ?? "stages-meetingId-planning-impression",
    path: planning_45impressionZ0cBUOl55nMeta?.path ?? "/stages/:meetingId()/planning-impression",
    meta: planning_45impressionZ0cBUOl55nMeta || {},
    alias: planning_45impressionZ0cBUOl55nMeta?.alias || [],
    redirect: planning_45impressionZ0cBUOl55nMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning-impression.vue").then(m => m.default || m)
  },
  {
    name: planningnkiBnGVS19Meta?.name ?? "stages-meetingId-planning",
    path: planningnkiBnGVS19Meta?.path ?? "/stages/:meetingId()/planning",
    meta: planningnkiBnGVS19Meta || {},
    alias: planningnkiBnGVS19Meta?.alias || [],
    redirect: planningnkiBnGVS19Meta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning.vue").then(m => m.default || m)
  },
  {
    name: servicesB129eJa9rwMeta?.name ?? "stages-meetingId-services",
    path: servicesB129eJa9rwMeta?.path ?? "/stages/:meetingId()/services",
    meta: servicesB129eJa9rwMeta || {},
    alias: servicesB129eJa9rwMeta?.alias || [],
    redirect: servicesB129eJa9rwMeta?.redirect || undefined,
    component: () => import("/app/webapp/pages/stages/[meetingId]/services.vue").then(m => m.default || m)
  }
]