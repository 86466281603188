/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResponseTypeEnum } from './ResponseTypeEnum';
import {
    ResponseTypeEnumFromJSON,
    ResponseTypeEnumFromJSONTyped,
    ResponseTypeEnumToJSON,
    ResponseTypeEnumToJSONTyped,
} from './ResponseTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingPublicForm
 */
export interface MeetingPublicForm {
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicForm
     */
    readonly id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingPublicForm
     */
    readonly is_answered: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingPublicForm
     */
    readonly is_open: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicForm
     */
    title: string;
    /**
     * 
     * @type {ResponseTypeEnum}
     * @memberof MeetingPublicForm
     */
    response_type: ResponseTypeEnum;
    /**
     * Date de publication du sondage
     * @type {Date}
     * @memberof MeetingPublicForm
     */
    publication_date?: Date | null;
    /**
     * Date d'ouverture du sondage
     * @type {Date}
     * @memberof MeetingPublicForm
     */
    opening_date?: Date | null;
    /**
     * Date de cloture du sondage
     * @type {Date}
     * @memberof MeetingPublicForm
     */
    closing_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicForm
     */
    author?: string | null;
}



/**
 * Check if a given object implements the MeetingPublicForm interface.
 */
export function instanceOfMeetingPublicForm(value: object): value is MeetingPublicForm {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('is_answered' in value) || value['is_answered'] === undefined) return false;
    if (!('is_open' in value) || value['is_open'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('response_type' in value) || value['response_type'] === undefined) return false;
    return true;
}

export function MeetingPublicFormFromJSON(json: any): MeetingPublicForm {
    return MeetingPublicFormFromJSONTyped(json, false);
}

export function MeetingPublicFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingPublicForm {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_answered': json['is_answered'],
        'is_open': json['is_open'],
        'title': json['title'],
        'response_type': ResponseTypeEnumFromJSON(json['response_type']),
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'author': json['author'] == null ? undefined : json['author'],
    };
}

export function MeetingPublicFormToJSON(json: any): MeetingPublicForm {
    return MeetingPublicFormToJSONTyped(json, false);
}

export function MeetingPublicFormToJSONTyped(value?: Omit<MeetingPublicForm, 'id'|'is_answered'|'is_open'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'response_type': ResponseTypeEnumToJSON(value['response_type']),
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date'] as any).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
        'author': value['author'],
    };
}

