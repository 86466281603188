/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingModule } from './MeetingModule';
import {
    MeetingModuleFromJSON,
    MeetingModuleFromJSONTyped,
    MeetingModuleToJSON,
    MeetingModuleToJSONTyped,
} from './MeetingModule';

/**
 * 
 * @export
 * @interface PaginatedMeetingModuleList
 */
export interface PaginatedMeetingModuleList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMeetingModuleList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingModuleList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingModuleList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MeetingModule>}
     * @memberof PaginatedMeetingModuleList
     */
    results: Array<MeetingModule>;
}

/**
 * Check if a given object implements the PaginatedMeetingModuleList interface.
 */
export function instanceOfPaginatedMeetingModuleList(value: object): value is PaginatedMeetingModuleList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedMeetingModuleListFromJSON(json: any): PaginatedMeetingModuleList {
    return PaginatedMeetingModuleListFromJSONTyped(json, false);
}

export function PaginatedMeetingModuleListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMeetingModuleList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(MeetingModuleFromJSON)),
    };
}

export function PaginatedMeetingModuleListToJSON(json: any): PaginatedMeetingModuleList {
    return PaginatedMeetingModuleListToJSONTyped(json, false);
}

export function PaginatedMeetingModuleListToJSONTyped(value?: PaginatedMeetingModuleList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(MeetingModuleToJSON)),
    };
}

