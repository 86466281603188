/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MeetingModule,
  ModuleNotFoundError,
  PaginatedMeetingModuleList,
  ToggleError,
  ToggleModuleResponse,
} from '../models/index';
import {
    MeetingModuleFromJSON,
    MeetingModuleToJSON,
    ModuleNotFoundErrorFromJSON,
    ModuleNotFoundErrorToJSON,
    PaginatedMeetingModuleListFromJSON,
    PaginatedMeetingModuleListToJSON,
    ToggleErrorFromJSON,
    ToggleErrorToJSON,
    ToggleModuleResponseFromJSON,
    ToggleModuleResponseToJSON,
} from '../models/index';

export interface MeetingModulesListRequest {
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
}

export interface MeetingModulesRetrieveRequest {
    uuid: string;
}

export interface MeetingsModulesToggleCreateRequest {
    meetingUuid: string;
    moduleUuid: string;
}

/**
 * 
 */
export class ModulesApi extends runtime.BaseAPI {

    /**
     * Un Module est une fonctionnalité de digiform pour une formation par exemple le Planning ou les Liens Utiles. Les modules peuvent être activés ou desactivés par formation.
     */
    async meetingModulesListRaw(requestParameters: MeetingModulesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingModuleList>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-modules/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingModuleListFromJSON(jsonValue));
    }

    /**
     * Un Module est une fonctionnalité de digiform pour une formation par exemple le Planning ou les Liens Utiles. Les modules peuvent être activés ou desactivés par formation.
     */
    async meetingModulesList(requestParameters: MeetingModulesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingModuleList> {
        const response = await this.meetingModulesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un Module est une fonctionnalité de digiform pour une formation par exemple le Planning ou les Liens Utiles. Les modules peuvent être activés ou desactivés par formation.
     */
    async meetingModulesRetrieveRaw(requestParameters: MeetingModulesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingModule>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingModulesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-modules/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingModuleFromJSON(jsonValue));
    }

    /**
     * Un Module est une fonctionnalité de digiform pour une formation par exemple le Planning ou les Liens Utiles. Les modules peuvent être activés ou desactivés par formation.
     */
    async meetingModulesRetrieve(requestParameters: MeetingModulesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingModule> {
        const response = await this.meetingModulesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable or disable a module for the specified meeting
     * Toggle module activation
     */
    async meetingsModulesToggleCreateRaw(requestParameters: MeetingsModulesToggleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ToggleModuleResponse>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsModulesToggleCreate().'
            );
        }

        if (requestParameters['moduleUuid'] == null) {
            throw new runtime.RequiredError(
                'moduleUuid',
                'Required parameter "moduleUuid" was null or undefined when calling meetingsModulesToggleCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/modules/{module_uuid}/toggle/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"module_uuid"}}`, encodeURIComponent(String(requestParameters['moduleUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ToggleModuleResponseFromJSON(jsonValue));
    }

    /**
     * Enable or disable a module for the specified meeting
     * Toggle module activation
     */
    async meetingsModulesToggleCreate(requestParameters: MeetingsModulesToggleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ToggleModuleResponse> {
        const response = await this.meetingsModulesToggleCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
