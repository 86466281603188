/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PublishMeetingForm
 */
export interface PublishMeetingForm {
    /**
     * 
     * @type {Date}
     * @memberof PublishMeetingForm
     */
    publication_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PublishMeetingForm
     */
    opening_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PublishMeetingForm
     */
    closing_date?: Date | null;
}

/**
 * Check if a given object implements the PublishMeetingForm interface.
 */
export function instanceOfPublishMeetingForm(value: object): value is PublishMeetingForm {
    return true;
}

export function PublishMeetingFormFromJSON(json: any): PublishMeetingForm {
    return PublishMeetingFormFromJSONTyped(json, false);
}

export function PublishMeetingFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishMeetingForm {
    if (json == null) {
        return json;
    }
    return {
        
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
    };
}

export function PublishMeetingFormToJSON(json: any): PublishMeetingForm {
    return PublishMeetingFormToJSONTyped(json, false);
}

export function PublishMeetingFormToJSONTyped(value?: PublishMeetingForm | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date']).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date']).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
    };
}

