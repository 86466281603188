/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedDailyTaskTeam } from './NotNestedDailyTaskTeam';
import {
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamFromJSONTyped,
    NotNestedDailyTaskTeamToJSON,
    NotNestedDailyTaskTeamToJSONTyped,
} from './NotNestedDailyTaskTeam';
import type { NotNestedPublicMeetingParticipantWithAvatar } from './NotNestedPublicMeetingParticipantWithAvatar';
import {
    NotNestedPublicMeetingParticipantWithAvatarFromJSON,
    NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped,
    NotNestedPublicMeetingParticipantWithAvatarToJSON,
    NotNestedPublicMeetingParticipantWithAvatarToJSONTyped,
} from './NotNestedPublicMeetingParticipantWithAvatar';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingFormSubmission
 */
export interface PatchedMeetingFormSubmission {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormSubmission
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingFormSubmission
     */
    readonly submitted_at?: Date;
    /**
     * 
     * @type {any}
     * @memberof PatchedMeetingFormSubmission
     */
    submission_data?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingFormSubmission
     */
    readonly url?: string;
    /**
     * 
     * @type {NotNestedPublicMeetingParticipantWithAvatar}
     * @memberof PatchedMeetingFormSubmission
     */
    participant?: NotNestedPublicMeetingParticipantWithAvatar;
    /**
     * 
     * @type {NotNestedDailyTaskTeam}
     * @memberof PatchedMeetingFormSubmission
     */
    team?: NotNestedDailyTaskTeam | null;
}

/**
 * Check if a given object implements the PatchedMeetingFormSubmission interface.
 */
export function instanceOfPatchedMeetingFormSubmission(value: object): value is PatchedMeetingFormSubmission {
    return true;
}

export function PatchedMeetingFormSubmissionFromJSON(json: any): PatchedMeetingFormSubmission {
    return PatchedMeetingFormSubmissionFromJSONTyped(json, false);
}

export function PatchedMeetingFormSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingFormSubmission {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'submitted_at': json['submitted_at'] == null ? undefined : (new Date(json['submitted_at'])),
        'submission_data': json['submission_data'] == null ? undefined : json['submission_data'],
        'url': json['url'] == null ? undefined : json['url'],
        'participant': json['participant'] == null ? undefined : NotNestedPublicMeetingParticipantWithAvatarFromJSON(json['participant']),
        'team': json['team'] == null ? undefined : NotNestedDailyTaskTeamFromJSON(json['team']),
    };
}

export function PatchedMeetingFormSubmissionToJSON(json: any): PatchedMeetingFormSubmission {
    return PatchedMeetingFormSubmissionToJSONTyped(json, false);
}

export function PatchedMeetingFormSubmissionToJSONTyped(value?: Omit<PatchedMeetingFormSubmission, 'id'|'submitted_at'|'url'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'submission_data': value['submission_data'],
        'participant': NotNestedPublicMeetingParticipantWithAvatarToJSON(value['participant']),
        'team': NotNestedDailyTaskTeamToJSON(value['team']),
    };
}

