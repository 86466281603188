/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingFormField } from './MeetingFormField';
import {
    MeetingFormFieldFromJSON,
    MeetingFormFieldFromJSONTyped,
    MeetingFormFieldToJSON,
    MeetingFormFieldToJSONTyped,
} from './MeetingFormField';
import type { MeetingFormSubmission } from './MeetingFormSubmission';
import {
    MeetingFormSubmissionFromJSON,
    MeetingFormSubmissionFromJSONTyped,
    MeetingFormSubmissionToJSON,
    MeetingFormSubmissionToJSONTyped,
} from './MeetingFormSubmission';
import type { ResponseTypeEnum } from './ResponseTypeEnum';
import {
    ResponseTypeEnumFromJSON,
    ResponseTypeEnumFromJSONTyped,
    ResponseTypeEnumToJSON,
    ResponseTypeEnumToJSONTyped,
} from './ResponseTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingPublicFormWithFields
 */
export interface MeetingPublicFormWithFields {
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicFormWithFields
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicFormWithFields
     */
    title: string;
    /**
     * 
     * @type {Array<MeetingFormField>}
     * @memberof MeetingPublicFormWithFields
     */
    readonly form_fields: Array<MeetingFormField>;
    /**
     * 
     * @type {ResponseTypeEnum}
     * @memberof MeetingPublicFormWithFields
     */
    response_type: ResponseTypeEnum;
    /**
     * Date de publication du sondage
     * @type {Date}
     * @memberof MeetingPublicFormWithFields
     */
    publication_date?: Date | null;
    /**
     * 
     * @type {Array<MeetingFormSubmission>}
     * @memberof MeetingPublicFormWithFields
     */
    readonly form_responses: Array<MeetingFormSubmission>;
    /**
     * Date d'ouverture du sondage
     * @type {Date}
     * @memberof MeetingPublicFormWithFields
     */
    opening_date?: Date | null;
    /**
     * Date de cloture du sondage
     * @type {Date}
     * @memberof MeetingPublicFormWithFields
     */
    closing_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublicFormWithFields
     */
    author?: string | null;
}



/**
 * Check if a given object implements the MeetingPublicFormWithFields interface.
 */
export function instanceOfMeetingPublicFormWithFields(value: object): value is MeetingPublicFormWithFields {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('form_fields' in value) || value['form_fields'] === undefined) return false;
    if (!('response_type' in value) || value['response_type'] === undefined) return false;
    if (!('form_responses' in value) || value['form_responses'] === undefined) return false;
    return true;
}

export function MeetingPublicFormWithFieldsFromJSON(json: any): MeetingPublicFormWithFields {
    return MeetingPublicFormWithFieldsFromJSONTyped(json, false);
}

export function MeetingPublicFormWithFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingPublicFormWithFields {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'form_fields': ((json['form_fields'] as Array<any>).map(MeetingFormFieldFromJSON)),
        'response_type': ResponseTypeEnumFromJSON(json['response_type']),
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'form_responses': ((json['form_responses'] as Array<any>).map(MeetingFormSubmissionFromJSON)),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'author': json['author'] == null ? undefined : json['author'],
    };
}

export function MeetingPublicFormWithFieldsToJSON(json: any): MeetingPublicFormWithFields {
    return MeetingPublicFormWithFieldsToJSONTyped(json, false);
}

export function MeetingPublicFormWithFieldsToJSONTyped(value?: Omit<MeetingPublicFormWithFields, 'id'|'form_fields'|'form_responses'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'response_type': ResponseTypeEnumToJSON(value['response_type']),
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date'] as any).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
        'author': value['author'],
    };
}

